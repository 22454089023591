.footer {
  background-color: #212129;
  color: #fff;
  padding-top: 112px;
  padding-bottom: 80px;
  margin-top: 140px;

  @media (max-width: 480px) {
    margin-top: 64px;
    padding-top: 64px;
    padding-bottom: 40px;
  }

  a {
    cursor: pointer;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .twoColumn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 200px;

    @media (max-width: 780px) {
      display: block;
    }

    @media (max-width: 480px) {
      margin-top: 104px;
    }
  }

  .rightColumn {
    display: flex;
    text-align: right;

    @media (max-width: 780px) {
      margin-top: 40px;
    }

    li {
      & + li {
        margin-left: 56px;
      }

      a {
        color: #fff;
        font-size: 18px;
        font-weight: 400;

        @media (max-width: 960px) {
          font-size: 16px;
        }
      }
    }
  }
}
