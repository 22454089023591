/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Thin.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Thin.woff') format('woff'),
      url('./fonts/HelveticaNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Light.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Light.woff') format('woff'),
      url('./fonts/HelveticaNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue.woff2') format('woff2'),
      url('./fonts/HelveticaNeue.woff') format('woff'),
      url('./fonts/HelveticaNeue.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Italic.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Italic.woff') format('woff'),
      url('./fonts/HelveticaNeue-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Medium.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Medium.woff') format('woff'),
      url('./fonts/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Bold.woff2') format('woff2'),
      url('./fonts/HelveticaNeue-Bold.woff') format('woff'),
      url('./fonts/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
