.mainTitle {
  padding-top: 352px;

  @media (max-width: 960px) {
    padding-top: 312px;
  }

  @media (max-width: 480px) {
    padding-top: 256px;
  }
}

.clients {

  .clientContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 56px;

    @media (max-width: 960px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 780px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      display: block;
    }

    .client + .client{
      @media (max-width: 480px) {
        margin-top: 40px;
      }
    }
  }
}
