.rc-collapse-motion {
  transition: height 0.3s, opacity 0.3s;
}
.rc-collapse-content-hidden {
  display: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  line-height: 1.5;
}

@media (max-width: 960px) {
  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    font-size: 20px;
  }
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header svg {
  transition: transform 0.2s;
  margin-right: 16px;
  width: 24px;
  height: 24px;
}
.rc-collapse > .rc-collapse-item + .rc-collapse-item {
  margin-top: 16px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
  display: inline-block;
  content: "\20";
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid #666;
  vertical-align: middle;
  margin-right: 8px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}
.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only {
  cursor: default;
}
.rc-collapse
  > .rc-collapse-item
  .rc-collapse-header-collapsible-only
  .rc-collapse-header-text {
  cursor: pointer;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}
.rc-collapse-content {
  overflow: hidden;
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 24px;
  margin-bottom: 32px;
}
.rc-collapse > .rc-collapse-item-active .rc-collapse-header svg {
  transform: rotate(180deg);
}
