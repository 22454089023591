.MainWrapper {
  max-width: 1390px;
  margin: auto;
  padding: 0 40px;

  @media (max-width: 480px) {
    padding: 0 16px;
  }

  &[size="10"] {
    padding: 0 122px;

    @media (max-width: 960px) {
      padding: 0 64px;
    }

    @media (max-width: 480px) {
      padding: 0 32px;
    }
  }

  &[size="8"] {
    padding: 0 204px;

    @media (max-width: 960px) {
      padding: 0 104px;
    }

    @media (max-width: 480px) {
      padding: 0 48px;
    }
  }
}
