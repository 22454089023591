.contact {
  padding-top: 352px;
  padding-bottom: 136px;

  @media (max-width: 960px) {
    padding-top: 312px;
    padding-bottom: 96px;
  }

  @media (max-width: 480px) {
    padding-top: 256px;
    padding-bottom: 56px;
  }

  h3 + h1 {
    margin-top: 40px;
  }

  a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}