.twoColumn {
  display: flex;
  justify-content: space-between;

  @media (max-width: 780px) {
    display: block;
  }

  .leftColumn,
  .rightColumn {
    width: calc(50% - 24px);

    @media (max-width: 780px) {
      width: 100%;
    }
  }

  .leftColumn + .rightColumn {
    @media (max-width: 780px) {
      margin-top: 40px;
    }
  }
}

.projectTitle {
  padding-top: 352px;
  padding-bottom: 136px;

  @media (max-width: 960px) {
    padding-top: 312px;
    padding-bottom: 96px;
  }

  @media (max-width: 480px) {
    padding-top: 256px;
    padding-bottom: 56px;
  }
}

.imageFull {
  width: 100%;

  & + .imageFull,
  & + .imageHalf {
    padding-top: 48px;

    @media (max-width: 480px) {
      padding-top: 24px;
    }
  }

  .imageRatio {
    position: relative;
    width: 100%;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: "";
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.imageHalf {
  width: 100%;

  & + .imageFull,
  & + .imageHalf {
    padding-top: 48px;

    @media (max-width: 480px) {
      padding-top: 24px;
    }
  }

  .imageRatio {
    position: relative;
    width: 100%;

    &:after {
      padding-top: 112.5%;
      display: block;
      content: "";
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.quote {
  .profile {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .profilePicture {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .profileDetails {
      margin-left: 16px;

      p + p {
        margin-top: 0;
      }
    }
  }
}

.contentHalf {
  .rightColumn {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 780px) {
      display: block;
    }

    .content {
      width: calc(50% - 24px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }
  }
}
