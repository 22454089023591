h1 {
  font-size: 60px;
  line-height: 1.5;

  @media (max-width: 960px) {
    font-size: 50px;
  }

  @media (max-width: 780px) {
    font-size: 40px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
}

h3 {
  font-size: 30px;
  line-height: 1.5;

  @media (max-width: 960px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }

  & + p {
    margin-top: 24px;

    @media (max-width: 480px) {
      margin-top: 16px;
    }
  }

  strong {
    font-weight: 600;
  }
}

p {
  font-size: 18px;
  line-height: 1.5;

  @media (max-width: 960px) {
    font-size: 16px;
  }

  & + p {
    margin-top: 16px;

    @media (max-width: 480px) {
      margin-top: 8px;
    }
  }

  strong {
    font-weight: 600;
  }
}
